import forge from "node-forge";

const dcir = document.getElementById("service-url").innerHTML?.length
  ? document.getElementById("service-url").innerHTML.trim()
  : "https://dcir-management-service.development.teamapt.com";

const isAzureAdEnabled = document.getElementById("azureAdEnabled").innerHTML
  ?.length
  ? document.getElementById("azureAdEnabled").innerHTML.trim() === "true"
  : true;

const isEncryptedLogin = document.getElementById("isEncryptedLogin").innerHTML
  ?.length
  ? document.getElementById("isEncryptedLogin").innerHTML.trim() === "true"
  : true;

const isEncryptedLoginResponse = document.getElementById("isEncryptedLoginResponse")
  .innerHTML?.length
  ? document.getElementById("isEncryptedLoginResponse").innerHTML.trim() ===
    "true"
  : false;

const getStorageType = (storageType) => {
  switch (storageType) {
    case "LOCAL_STORAGE":
    case "SESSION_STORAGE":
    case "IN_MEMORY_STORAGE":
      return storageType;
    default:
      return "LOCAL_STORAGE";
  }
};

const storageType = document.getElementById("storageType").innerHTML?.length
  ? getStorageType(document.getElementById("storageType").innerHTML.trim())
  : "LOCAL_STORAGE";

const publicKeyPem = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA6A5N3GlOw4kUrdkQs2rF
Jyqht7tJXlAk39sh+vK+TVWCMKJHiiN6xiNT7tpzfMbgojW2oXRQdgZhi6C1wRLI
ZpdFopU0cL/NyDD6nOYOi2hYjdhUvU+rFov69iKCrElXmsbVFzM6cp9SwDUkT3T8
yxlUXADOuehftqVRoAwq+LWI/ODi4zEeBI/H1rkScxKjxOPRi88HGS6GwfX5kZZO
2L0HF4EdubYlaJRlD21S5Sk1N2GW4WdXQBRvxuEsQavJQ96k5amrN4W2V8Y6nZq8
Nkq4U7XJmOsWDuikPXVNX5CTD7TbRAbtkVlRjBMdEzQ1X3O40onESCHJFT4JKZ4m
kwIDAQAB
-----END PUBLIC KEY-----`;

const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);

const azureClientId = document.getElementById("azureClientId").innerHTML?.length
  ? document.getElementById("azureClientId").innerHTML.trim()
  : "";

const azureTenantId = document.getElementById("azureTenantId").innerHTML?.length
  ? document.getElementById("azureTenantId").innerHTML.trim()
  : "";

const originUrl = window.location.origin;

const formatOriginUrlToRedirectUrl = (originUrl) => {
  return originUrl.replace(/:/g, "%3A").replace(/\//g, "%2F");
};

const azureLoginUrl = `https://login.microsoftonline.com/${azureTenantId}/oauth2/v2.0/authorize?client_id=${azureClientId}&response_type=id_token&redirect_uri=${formatOriginUrlToRedirectUrl(
  originUrl
)}%2Flogin%2Fazure-ad&response_mode=fragment&scope=openid+profile+email+user.read&state=12345&nonce=678910`;

const monitoringServiceUrl = document
  .getElementById("monitoring-service-url")
  ?.innerHTML.trim();

const authUserName = "dcir-management-service";

const authPassword = "secret";

const grantType = "password";

const app_data = "mock";

const monitoringApiUrl = monitoringServiceUrl?.length
  ? monitoringServiceUrl
  : "https://aptpay-monitoring.development.teamapt.com";

const bankPrivacyPolicyUrl = document.getElementById("bankPrivacyPolicyUrl")
  .innerHTML?.length
  ? document.getElementById("bankPrivacyPolicyUrl").innerHTML.trim()
  : "";

const bankTermsUrl = document.getElementById("bankTermsUrl").innerHTML?.length
  ? document.getElementById("bankTermsUrl").innerHTML.trim()
  : "";

export const CONFIG = {
  BASE_URL: `${dcir}/api/v1`,
  MONITORING_HOST: monitoringApiUrl,
  APP_DATA: `${app_data}`,
  STORAGE_TYPE: storageType,
  AUTH_USER_NAME: `${authUserName}`,
  AUTH_PASSWORD: `${authPassword}`,
  DOMAIN_ID: "MONIEPOINT",
  GRANT_TYPE: `${grantType}`,
  CLIENT_ID: "WEB",
  mock: true,
  APP_TIMEOUT: 2000,
  REDIRECT_ACCESS_TOKEN: null,
  REDIRECT_LOGIN_USER_TYPE: "1",
  IS_AZURE_AD_ENABLED: isAzureAdEnabled,
  AZURE_TENANT_ID: azureTenantId,
  AZURE_CLIENT_ID: azureClientId,
  AZURE_LOGIN_URL: azureLoginUrl,
  IS_ENCRYPTED_LOGIN: isEncryptedLogin,
  IS_ENCRYPTED_LOGIN_RESPONSE: isEncryptedLoginResponse,
  PUBLIC_KEY: publicKey,
  BANK_PRIVACY_POLICY_URL: bankPrivacyPolicyUrl,
  BANK_TERMS_URL: bankTermsUrl,
};
