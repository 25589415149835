import { STORAGE } from "utils/storage";
import { HELPER } from "utils/helper";
import { CONFIG } from "config/config";

export function persistLoginDataAndRedirect(
  data,
  mainContext,
  history,
  isChangePasswordAutoLogin = false
) {
  if (CONFIG.IS_ENCRYPTED_LOGIN_RESPONSE) {
    data = JSON.parse(HELPER.ENCRYPTION_SERVICE.decode(data));
  }
  const authorities = data?.cosmosResponse?.authorities;

  let loginRoute = HELPER.GET_USER_LOGIN_ROUTE(authorities);
  let userType = HELPER.getUserRoleType(authorities);
  let selectedSideNav = loginRoute.split("/")[2].split("-").join(" ");
  let loginData = {
    username: data?.username,
    email: data?.email,
    access_token: data?.cosmosResponse?.accessToken,
    authorities: authorities,
    expires_in: data?.cosmosResponse?.expiresIn,
    firstName: data?.firstName,
    lastName: data?.lastName,
    roles: data?.cosmosResponse?.roles,
    isAuthenticated: true,
    name: data?.firstname + " " + data?.lastname,
    selectedSideNav: selectedSideNav,
    selectedSideNavIndex: 0,
    merchantId: data?.merchantId,
    merchantName: data?.branch,
    userType,
  };
  // const role = data.roles[0];
  // loginData.selectedSideNavIndex =
  //   role === "DCIR_MERCHANT_SUPER_ADMIN" ? 0 : 1;
  STORAGE.SAVE_USER_CREDENTIAL(loginData);
  mainContext.mainDispatch({
    type: "PERSIST_LOGIN_DATA",
    loginData: loginData,
  });
  // Changing deafult password requires temporarily storing the users login data which might still have
  // the "resetPassword" property as true.
  if (data.resetPassword && !isChangePasswordAutoLogin) {
    history.push("/change-password");
  } else {
    history.push(loginRoute);
  }
}

export function persistMonitoringLoginDataAndRedirect(
  data,
  mainContext,
  history
) {
  let loginRoute = "/monitor/transaction-processing";
  // HELPER.GET_USER_LOGIN_ROUTE(
  //   data?.cosmosResponse?.authorities
  // );
  let selectedSideNav = loginRoute.split("/")[2].split("-").join(" ");
  let loginData = {
    username: data?.username,
    email: data?.email,
    access_token: data?.token,
    authorities: data?.cosmosResponse?.authorities,
    expires_in: data?.cosmosResponse?.expiresIn,
    firstName: data?.firstName,
    lastName: data?.lastName,
    roles: data?.cosmosResponse?.roles,
    isAuthenticated: true,
    name: data?.firstname + " " + data?.lastname,
    selectedSideNav: selectedSideNav,
    selectedSideNavIndex: 0,
    merchantId: data?.merchantId,
    merchantName: data?.branch,
  };
  // const role = data.roles[0];
  // loginData.selectedSideNavIndex =
  //   role === "DCIR_MERCHANT_SUPER_ADMIN" ? 0 : 1;
  const isMonitoringLogin = true;

  STORAGE.SAVE_USER_CREDENTIAL(loginData, isMonitoringLogin);
  mainContext.monitoringDispatch({
    type: "PERSIST_LOGIN_DATA",
    loginData: loginData,
  });
  // Changing deafult password requires temporarily storing the users login data which might still have
  // the "resetPassword" property as true.

  history.push(loginRoute);
}
